import React, { useEffect, useCallback } from 'react';
import { parse } from 'querystring';
import {
  authorizeUser,
  setAccessToken,
  setRefreshToken,
  getConnectURL,
} from '../util/auth';

export default ({ location, navigate }) => {
  const handleCallback = useCallback(
    async ({ code, state }) => {
      try {
        const { accessToken, refreshToken } = await authorizeUser(code, state);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        navigate('/');
      } catch (e) {
        console.error('something went wrong', e);
        navigate('/');
      }
    },
    [navigate]
  );

  const params = parse(location.search.substr(1));

  const isConnecting = !!params.code;

  useEffect(() => {
    // TODO: handle errors passed in query params
    // https://developer.spotify.com/documentation/general/guides/authorization-guide/#scopes
    if (isConnecting) {
      handleCallback(params);
    }
  }, [handleCallback, params]);

  console.log('hi');

  return isConnecting ? (
    <span>Connecting...</span>
  ) : (
    <div
      style={{
        textAlign: 'center',
        margin: '30px',
      }}
    >
      <a className="btn btn-primary" href={getConnectURL()}>
        Connect Spotify
      </a>
    </div>
  );
};
