import React from 'react';

export default ({ artist, onClick = () => {}, active = false }) => (
  <div
    className={`item ${active ? 'active' : ''}`}
    key={artist.id}
    onClick={onClick}
  >
    <div
      className="image shadow rounded-circle"
      style={{
        backgroundImage: artist.images[0]
          ? `url(${artist.images[0].url})`
          : null,
      }}
    ></div>
    <div className="name">{artist.name}</div>
  </div>
);
