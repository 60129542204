import React, { useState, useCallback } from 'react';
import { Redirect } from '@reach/router';
import { isConnected } from '../util/auth';
import { getRelatedArtists, getArtistTopTracks } from '../util/spotify';
import { playBlendedTracks } from '../util/playback';
import Search from '../components/Search';
import ArtistBubble from '../components/ArtistBubble';
import { GoogleSpeechSynthesis } from 'google-cloud-speech-webaudio';

const GOOGLE_API_KEY = 'AIzaSyDqcyGHuuShip92_m8zjhxFe4uZXxFjjIM';
const speechSynthesis = new GoogleSpeechSynthesis(GOOGLE_API_KEY);

async function fetchArtist(id) {
  const [relatedArtists, topTracks] = await Promise.all([
    getRelatedArtists(id),
    getArtistTopTracks(id),
  ]);

  return { relatedArtists, topTracks };
}

const scrollLevelToArtist = (levelIndex, artistIndex) => {
  document
    .getElementById(`level-${levelIndex}`)
    .scrollTo({ left: artistIndex * 120, behavior: 'smooth' });
};

const simplifiedTrackName = (trackName) => {
  let result = trackName;

  const dashPosition = result.indexOf(' - ');
  if (dashPosition > 0) {
    result = result.substr(0, dashPosition);
  }

  const paranthesesPosition = result.indexOf('(');
  if (paranthesesPosition > 0) {
    result = result.substr(0, paranthesesPosition);
  }

  return result;
};

const setBackgroundImage = (url) => {
  if (!url) {
    document.body.style.backgroundImage = 'none';
  } else {
    const img = new Image();
    img.onload = () => (document.body.style.backgroundImage = `url(${url})`);
    img.src = url;
  }
};

const App = () => {
  const [history, setHistory] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleClick = useCallback(
    async (levelIndex, artistIndex, artist) => {
      setBackgroundImage(artist.images[0]?.url);
      setSelected([...selected.slice(0, levelIndex), artistIndex]);
      scrollLevelToArtist(levelIndex, artistIndex);

      const { topTracks, relatedArtists } = await fetchArtist(artist.id);

      if (levelIndex === 0) {
        setHistory([[artist], relatedArtists]);
      } else {
        setHistory([...history.slice(0, levelIndex + 1), relatedArtists]);
      }

      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

      await playBlendedTracks(topTracks, async (track) => {
        console.log('now playing', track);
        setBackgroundImage(track.album.images[0]?.url);
        await speechSynthesis.speak(simplifiedTrackName(track.name));
      });
    },
    [history, selected]
  );

  return (
    <>
      <div id="backgroundImage"></div>
      <Search onSearchResultsChanged={(artists) => setHistory([artists])} />

      {history.map((artists, levelIndex) => (
        <div
          id={`level-${levelIndex}`}
          key={levelIndex}
          className="level"
          // onScroll={(e) => {
          //   const scrollLeft = e.target.scrollLeft;
          //   const totalWidth = e.target.scrollWidth;

          //   document
          //     .querySelectorAll(`#level-${levelIndex} .item`)
          //     .forEach((item, index) => {
          //       const elementLeft = 120 * index;
          //       const delta = Math.abs(elementLeft - scrollLeft);
          //       const scale = 1 - delta / totalWidth;
          //       item.style.transform = `translateX(-50%) scale(${
          //         scale * scale * scale
          //       })`;
          //     });
          // }}
          onMouseLeave={() => {
            if (selected[levelIndex]) {
              scrollLevelToArtist(levelIndex, selected[levelIndex]);
            }
          }}
        >
          {artists.map((artist, artistIndex) => (
            <ArtistBubble
              key={artist.id}
              active={selected[levelIndex] === artistIndex}
              artist={artist}
              onClick={() => {
                handleClick(levelIndex, artistIndex, artist);
              }}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default () =>
  isConnected() ? <App /> : <Redirect from="/" to="/connect" noThrow />;
