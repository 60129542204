const fetch = require('node-fetch');
const { getAccessToken } = require('./auth');

const DEFAULT_MARKET = 'from_token';

export async function getArtist(artist) {
  const response = await fetch(`https://api.spotify.com/v1/artists/${artist}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await getAccessToken()),
    },
  });

  return await response.json();
}

export async function getArtistTopTracks(artist, market = DEFAULT_MARKET) {
  const response = await fetch(
    `https://api.spotify.com/v1/artists/${artist}/top-tracks?market=${market}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessToken()),
      },
    }
  );

  const { tracks } = await response.json();

  return tracks;
}

export async function getRelatedArtists(artist) {
  const response = await fetch(
    `https://api.spotify.com/v1/artists/${artist}/related-artists`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessToken()),
      },
    }
  );

  const { artists } = await response.json();

  return artists;
}

export async function searchArtists(query) {
  const response = await fetch(
    `https://api.spotify.com/v1/search?q=${query}&type=artist`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessToken()),
      },
    }
  );

  const { artists } = await response.json();

  return artists.items;
}
