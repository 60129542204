const fetch = require('node-fetch');

let audioContext;

const TRACK_DURATION = 10;
const FADE_DURATION = 1;

async function getSongPreviewAudioBuffer(url) {
  const result = await fetch(url);
  const buffer = await result.arrayBuffer();

  return new Promise((resolve, reject) => {
    audioContext.decodeAudioData(buffer, resolve);
  });
}

function createSongNodes(audioBuffer) {
  const gainNode = audioContext.createGain(); // create a gain node.
  gainNode.connect(audioContext.destination); // connect the gain node to the destination.

  const source = audioContext.createBufferSource(); // creates a sound source
  source.connect(gainNode); // connect the source to the gain node.
  source.buffer = audioBuffer; // tell the source which sound to play

  return { source, gainNode };
}

let scheduledSongs = [];

// function onSongChanged(artist, track) {
//   const currentArtist = document.getElementById('artist').innerHTML;
//   let text;
//   if (currentArtist !== artist) {
//     text = artist + ' - ' + track;
//   } else {
//     text = track;
//   }

//   document.getElementById('track').innerHTML = text;
// }

export async function playBlendedTracks(tracks, onSongChanged) {
  if (!audioContext) {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContext = new AudioContext();
    audioContext.resume(); // Safari needs this for some reason
  }

  if (scheduledSongs.length > 0) {
    scheduledSongs.map(({ source, timer }) => {
      source.stop();
      clearTimeout(timer);
    });
    scheduledSongs = [];
  }

  const audioBuffers = await Promise.all(
    tracks.map((track) => getSongPreviewAudioBuffer(track.preview_url))
  );

  const nodes = audioBuffers.map(createSongNodes);

  const now = audioContext.currentTime;
  for (let index = 0; index < nodes.length; index++) {
    const { source, gainNode } = nodes[index];
    const startTimeDelta = (TRACK_DURATION - FADE_DURATION) * index;
    const startTimeAbsolute = now + startTimeDelta;
    const endTimeAbsolute = startTimeAbsolute + TRACK_DURATION;

    gainNode.gain.value = 0;
    gainNode.gain.linearRampToValueAtTime(0, startTimeAbsolute);
    gainNode.gain.linearRampToValueAtTime(
      1,
      startTimeAbsolute + FADE_DURATION * 2
    );
    gainNode.gain.linearRampToValueAtTime(1, endTimeAbsolute - FADE_DURATION);
    gainNode.gain.linearRampToValueAtTime(0, endTimeAbsolute);

    source.start(startTimeAbsolute, 0, TRACK_DURATION);

    const timer = setTimeout(() => {
      onSongChanged(tracks[index]);
    }, startTimeDelta * 1000);

    scheduledSongs.push({ source, timer });
  }
}
