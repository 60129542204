import React from 'react';
import { render } from 'react-dom';
import { Router } from '@reach/router';

import App from './pages/App';
import Connect from './pages/Connect';

render(
  <Router>
    <App path="/" />
    <Connect path="/connect" />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
