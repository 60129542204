import React, { useEffect, useState, useRef } from 'react';
import { searchArtists } from '../util/spotify';

export default ({ onSearchResultsChanged }) => {
  const [query, setQuery] = useState('');

  const inputRef = useRef();

  const search = async (query) => {
    const artists = await searchArtists(query);
    onSearchResultsChanged(artists);
  };

  useEffect(() => {
    if (query.length > 1) {
      search(query);
    }
  }, [query]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <div id="search">
        <input
          ref={inputRef}
          type="text"
          value={query}
          placeholder="Start by searching for an artist you like"
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
    </>
  );
};
