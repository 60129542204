import querystring from 'querystring';
import { v4 as uuidv4 } from 'uuid';

const API_DOMAIN = 'https://europe-west3-music-656f7.cloudfunctions.net';
const SPOTIFY_CLIENT_ID = '34acd9e534cd496b81e968b5f95ccb2a';
const SPOTIFY_REDIRECT_URI =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/connect'
    : 'https://music.andrei.codes/connect';

let accessToken;

export function setAccessToken(newAccessToken) {
  accessToken = newAccessToken;
}

export async function getAccessToken() {
  if (!accessToken) {
    const { accessToken } = await refreshAccessToken(getRefreshToken());
    setAccessToken(accessToken);
  }

  return accessToken;
}

export function setRefreshToken(refreshToken) {
  localStorage.setItem('refreshToken', refreshToken);
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function isConnected() {
  return getRefreshToken() !== null;
}

function secret() {
  let secret = localStorage.getItem('secret');
  if (secret === null) {
    secret = uuidv4();
    localStorage.setItem('secret', secret);
  }

  return secret;
}

export function getConnectURL() {
  return (
    'https://accounts.spotify.com/authorize?' +
    querystring.stringify({
      response_type: 'code',
      client_id: SPOTIFY_CLIENT_ID,
      scope: [],
      redirect_uri: SPOTIFY_REDIRECT_URI,
      state: secret(),
    })
  );
}

export async function authorizeUser(code, state) {
  if (state !== secret()) {
    throw new Error('Incorrect state');
  }

  localStorage.removeItem('secret');

  const response = await fetch(
    `${API_DOMAIN}/authorizeSpotifyUser?code=${code}&redirectURI=${SPOTIFY_REDIRECT_URI}`
  );

  return await response.json();
}

export async function refreshAccessToken(refreshToken) {
  const response = await fetch(
    `${API_DOMAIN}/refreshAccessToken?refreshToken=${refreshToken}`
  );

  return await response.json();
}
